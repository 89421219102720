export const portfolios = [
  /*   {
      id: 1,
      appname: "R J Samuel",
      appurl: "http://www.rjsamuel.com",
      appDesc:
        "Created with Wordpress this author page is graphically pleasing, informative, and connected."
    }, */
  {
    id: 2,
    appname: "Tapestry website",
    appurl: "https://www.tapestry.space/",
    appDesc:
      "Responsive site using ReactJs, tachyons, particles.js, AWS S3, AWS CloudFront"
  },
  {
    id: 3,
    appname: "Character Equalizer",
    appurl: "http://rs-equalizer.s3-website-us-east-1.amazonaws.com",
    appDesc: "Simple responsive site using ReactJs, Redux, tachyons, AWS S3"
  },
  {
    id: 4,
    appname: "this.portfolio",
    appurl: "#0",
    appDesc:
      "Simple responsive site using ReactJs, tachyons, AWS S3, AWS CloudFront"
  },
  {
    id: 1,
    appname: "Angular exercise",
    appurl: "http://exerciseforrole.s3-website-us-east-1.amazonaws.com/",
    appDesc:
      "A simple Angular frontend showing Angular &'s drag and drop as well as functions created on the fly from a json class."
  }
];
